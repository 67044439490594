<template>
  <ion-page>
    <ion-content :fullscreen="true" class="article-details">
      <ion-grid class="thumbnail-area">
        <ArticleThumbnailCard :category="article.category"
                              :image="article.image"
                              :read-time="article.readTime"
                              :viewed="article.viewed"
                              :only-category-display="false"
                              :include-back-button="true"
        />
      </ion-grid>

      <div class="content-section">
        <ion-row class="highlight">
          Ewald Rieder ist ein beeindruckender Unternehmer und stolz auf die südtiroler Wurzeln der Sanuslife. (Quelle:
          MERANER MORGEN)
        </ion-row>

        <ion-row class="article-title">
          Mit Sanuslife macht Ewald Rieder ein gesundes Lebensgefühl zum Geschäft
        </ion-row>

        <ion-row class="description" id="description">
          <p>
            Bozen – Südtirol hat viele erfolgreiche Unternehmer hervor gebracht. Gerade in der Winterzeit denken da
            begeisterte Skifahrer wohl an die Leitner AG aus Sterzing, bringt man Innovationskraft und Marktstärke aus
            Südtirol ins Gespräch. Doch es sind auch immer wieder neue und innovative Macher und Geschäftsideen, die das
            Land unter dem Schlern hervor bringt.
          </p>

          <p>
            Mit Sanuslife hat Ewald Rieder zusammen mit Schwester Verena Rieder und Freund Florian Kerschbaumer die
            Gesundheit zum Unternehmensprojekt gemacht. Gesundheit aus klarem Wasser könnte man verkürzt die Wirkung von
            Sanuslife beschreiben. Kritiker sahen in Sanuslife oft nur eine quirlige Geschäftsidee, um möglichst viele
            Vertriebspartner anzulocken. Es wäre dann egal, ob man Wein, Wasser oder Staubsauger vertreibt, denn starke
            Vertriebskraft findet immer ihren Erfolg. Doch bei Ewald Rieder hat man es mit einem ruhigen, ehrlichen und
            besonnenen Unternehmer zu tun. Rieder ist kein Patriarch oder arroganter Unternehmensdirektor, sondern ein
            wirklich netter Kerl, der bei Mitarbeitern und Geschäftspartnern sehr beliebt ist. Man spürt auch im
            Gespräch,
            dass Ewald Rieder an sein Unternehmen und dessen Produkte glaubt. Er ist ganz Mensch.
          </p>

          <p>
            Von der bekannten deutschen Modedesignerin Jil Sander ist der Ausspruch überliefert: „Wenn man auf seinen
            Körper achtet, geht‘s auch dem Kopf besser.“ Dieser Maxime folgen auch viele Menschen, die bevorzugt
            basische
            Lebensmittel zu sich nehmen. Diese pflanzlichen Nahrungsmittel sorgen im Körper für eine gesunde Balance des
            Säure-Basen-Haushalts und beugen einem ungesunden Säureüberschuss vor. Während eine basische Ernährung für
            körperliches Wohlbefinden sorgt, haben saure Lebensmittel einen gegenteiligen Effekt: die Übersäuerung des
            Körpers beeinträchtigt die Gesundheit und damit das Wohlbefinden.
          </p>

          <p>
            <img :src="require('@/assets/images/demo-image-4.jpg')" alt="">
          </p>

          <p>
            Von der bekannten deutschen Modedesignerin Jil Sander ist der Ausspruch überliefert: „Wenn man auf seinen
            Körper achtet, geht‘s auch dem Kopf besser.“ Dieser Maxime folgen auch viele Menschen, die bevorzugt
            basische
            Lebensmittel zu sich nehmen.
          </p>
        </ion-row>
      </div>

      <div class="similar-posts-section">
        <ion-grid class="two-row-section">
          <ion-row>
            <ion-label class="section-title">
              Ähnliche Beiträge
            </ion-label>
          </ion-row>

          <ion-row :class="`row1`">
            <ion-col v-for="(article, index) in articleCollection" :key="index">
              <ArticleCardWithOutlineInfo :title="article.title"
                                          :category="article.category"
                                          :read-time="article.readTime"
                                          :viewed="article.viewed"
                                          :image="article.image"/>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>

    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {IonCol, IonContent, IonGrid, IonPage, IonRow} from '@ionic/vue'
import ArticleThumbnailCard from "@/components/cards/ArticleThumbnailCard.vue";
import ArticleCardWithOutlineInfo from "@/components/cards/ArticleCardWithOutlineInfo.vue";
import store from '@/store';
import {onMounted} from 'vue';

export default {
  name: "ArticleDetails",
  components: {IonContent, ArticleThumbnailCard, IonGrid, IonRow, IonPage, ArticleCardWithOutlineInfo, IonCol},
  setup: () => {
    const article = store.state.articles[0];
    const articleCollection = store.state.articles;

    onMounted(() => {
      if (document.querySelectorAll('#description p').length) {
        document.querySelectorAll('#description p').forEach(item => {
          if (item.getElementsByTagName('img').length) {
            item.setAttribute('style', 'margin: 0 -20px');
          }
        })
      }
    })
    return {
      article,
      articleCollection
    }
  }
}
</script>

<style scoped>

</style>
