
import {IonCol, IonContent, IonGrid, IonPage, IonRow} from '@ionic/vue'
import ArticleThumbnailCard from "@/components/cards/ArticleThumbnailCard.vue";
import ArticleCardWithOutlineInfo from "@/components/cards/ArticleCardWithOutlineInfo.vue";
import store from '@/store';
import {onMounted} from 'vue';

export default {
  name: "ArticleDetails",
  components: {IonContent, ArticleThumbnailCard, IonGrid, IonRow, IonPage, ArticleCardWithOutlineInfo, IonCol},
  setup: () => {
    const article = store.state.articles[0];
    const articleCollection = store.state.articles;

    onMounted(() => {
      if (document.querySelectorAll('#description p').length) {
        document.querySelectorAll('#description p').forEach(item => {
          if (item.getElementsByTagName('img').length) {
            item.setAttribute('style', 'margin: 0 -20px');
          }
        })
      }
    })
    return {
      article,
      articleCollection
    }
  }
}
